import React, { useState } from 'react';

import whatoffer1 from '../../img/whatoffer1.png'
import whatoffer2 from '../../img/whatoffer7.png'
import whatoffer3 from '../../img/whatoffer9.png'
import whatoffer4 from '../../img/whatoffer8.png'
import whatoffer5 from '../../img/whatoffer6.png'
import whatoffer6 from '../../img/whatoffer5.png'
import whatoffer7 from '../../img/whatoffer10.png'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';

import Fade from "react-reveal/Fade"

import 'swiper/css';

import button from '../../img/button.svg'

import { useTranslation } from "react-i18next";

function WhatOffer() {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);

  const [swiper, setSwiper] = useState({});

  const { t } = useTranslation();

  return (
      <>
        <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto'>
            <div className='bg-black h-[1px] w-[178px] xl:w-[280px] mx-[20px] xl:ml-0'></div>
            <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] mx-[20px] xl:ml-0'>· {t('home.22')}</h1>
            <div className='mt-[60px] xl:flex hidden'>
                <div>
                    <Fade><div className="relative">
                        <div
                        className="relative overflow-hidden"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        >
                        <img className={`w-full h-full object-cover ${isHovered ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer1} alt="" />
                        <div className={`absolute left-0 top-[216px] w-[433px] h-auto flex bg-white ${isHovered ? 'fade-in show' : 'fade-in'}`}>
                            <div className='mx-[40px]'>
                            <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.23')}</h1>
                            <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.24')}</p>
                            </div>
                        </div>
                        </div>
                    </div></Fade>
                    <Fade delay={100}><div className='mt-[20px] relative'>
                        <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered1(true)}
                            onMouseLeave={() => setIsHovered1(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered1 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer2} alt="" />
                            <div className={`absolute left-0 top-[79px] w-[433px] h-auto flex bg-white ${isHovered1 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.25')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.26')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                    <Fade delay={100}><div className='mt-[20px] relative'>
                        <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered2(true)}
                            onMouseLeave={() => setIsHovered2(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered2 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer3} alt="" />
                            <div className={`absolute left-0 top-[143px] w-[433px] h-auto flex bg-white ${isHovered2 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.27')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.28')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                    <Fade delay={100}><div className='mt-[20px] relative'>
                        <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered3(true)}
                            onMouseLeave={() => setIsHovered3(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered3 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer4} alt="" />
                            <div className={`absolute left-0 top-[97px] w-[433px] h-auto flex bg-white ${isHovered3 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.29')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.30')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                </div>
                <div className='ml-auto'>
                    <Fade><div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered4(true)}
                            onMouseLeave={() => setIsHovered4(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered4 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer5} alt="" />
                            <div className={`absolute left-0 top-[154px] w-[433px] h-auto flex bg-white ${isHovered4 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.31')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.32')}</p>
                                </div>
                            </div>
                        </div></Fade>
                    <Fade delay={100}><div className='mt-[20px]'>
                         <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered5(true)}
                            onMouseLeave={() => setIsHovered5(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered5 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer6} alt="" />
                            <div className={`absolute left-0 top-[175px] w-[433px] h-auto flex bg-white ${isHovered5 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.33')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.34')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                    <Fade delay={100}><div className='mt-[20px]'>
                    <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered6(true)}
                            onMouseLeave={() => setIsHovered6(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered6 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer7} alt="" />
                            <div className={`absolute left-0 top-[224px] w-[433px] h-auto flex bg-white ${isHovered6 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.35')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.36')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                </div>
            </div>
            <div className='mt-[40px] block xl:hidden'>
                <Swiper
                    modules={[Navigation, Autoplay]}
                    slidesPerView={2}
                    spaceBetween={10}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    className='mt-[40px]'
                    onInit={(e) => {
                        setSwiper(e);
                      }}
                      breakpoints={{
                        1: {
                            slidesPerView: 1,
                          },
                        680: {
                          slidesPerView: 2,
                        },
                      }}
                >
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-auto mx-auto">
                            <div className='mx-auto w-[335px] md:w-full h-[256px] overflow-hidden relative'>
                                <img src={whatoffer1} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.23')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.24')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-auto mx-auto">
                            <div className='mx-auto w-[335px] md:w-full h-[256px] overflow-hidden relative'>
                                <img src={whatoffer2} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.25')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.26')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-auto mx-auto">
                            <div className='mx-auto w-[335px] md:w-full h-[256px] overflow-hidden relative'>
                                <img src={whatoffer3} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.27')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.28')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-auto mx-auto">
                            <div className='mx-auto w-[335px] md:w-full h-[256px] overflow-hidden relative'>
                                <img src={whatoffer4} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.29')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.30')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-auto mx-auto">
                            <div className='mx-auto w-[335px] md:w-full h-[256px] overflow-hidden relative'>
                                <img src={whatoffer5} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.31')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.32')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-auto mx-auto">
                            <div className='mx-auto w-[335px] md:w-full h-[256px] overflow-hidden relative'>
                                <img src={whatoffer6} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.33')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.34')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-auto mx-auto">
                            <div className='mx-auto w-[335px] md:w-full h-[256px] overflow-hidden relative'>
                                <img src={whatoffer7} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.35')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.36')}</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className='flex mx-auto w-[335px] mt-[40px]'>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] rotate-180'
                    />
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px] ml-auto'
                    />
                </div>
            </div>
        </div>
    </>
  );
}

export default WhatOffer;
