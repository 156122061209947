import React from 'react';

import { useState } from "react";

import why1 from '../../img/Services/rec3.jpg'
import why2 from '../../img/Services/rec2.jpg'
import why3 from '../../img/Services/rec1.jpg'

import button from '../../img/button.svg'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css';

import { useTranslation } from "react-i18next";

function Advantages() {

  const [swiper, setSwiper] = useState({});

  const { t } = useTranslation();
  
  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[120px] xl:mt-[180px] pb-[120px] xl:pb-[180px]'>
            <div className='bg-black h-[1px] w-[178px] xl:w-[280px] mx-[20px] xl:ml-0'></div>
            <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] mx-[20px] xl:ml-0'>· {t('services.47')}</h1>
            <div className='flex mt-[60px] gap-[50px] hidden xl:flex'>
                <div class='relative w-[393px] h-[339px]'>
                    <img src={why3} alt="" class='absolute w-full h-full'/>
                    <div class='relative flex-col'>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium'>01</p>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[210px]'>{t('services.41')}</p>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 border border-black">
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-light'>01</p>
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[50px]'>{t('services.41')}</p>
                        <p className='textcolor text-[16px] font-light w-[300px] mx-[40px] mt-[12px]'>{t('services.44')}</p>
                    </div>
                </div>
                <div class='relative w-[393px] h-[339px]'>
                    <img src={why2} alt="" class='absolute w-full h-full'/>
                    <div class='relative flex-col'>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium'>02</p>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[210px]'>{t('services.42')}</p>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 border border-black">
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-light'>02</p>
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[50px]'>{t('services.42')}</p>
                        <p className='textcolor text-[16px] font-light w-[300px] mx-[40px] mt-[12px]'>{t('services.45')}</p>
                    </div>
                </div>
                <div class='relative w-[393px] h-[339px]'>
                    <img src={why1} alt="" class='absolute w-full h-full'/>
                    <div class='relative flex-col'>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium'>03</p>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[210px]'>{t('services.43')}</p>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 border border-black">
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-light'>03</p>
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[50px]'>{t('services.43')}</p>
                        <p className='textcolor text-[16px] font-light w-[300px] mx-[40px] mt-[12px]'>{t('services.46')}</p>
                    </div>
                </div>
            </div>
            <div className='mt-[40px] block xl:hidden'>
                <Swiper
                    modules={[Navigation, Autoplay]}
                    slidesPerView={1}
                    spaceBetween={10}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    className='mt-[40px]'
                    onInit={(e) => {
                        setSwiper(e);
                      }}
                >
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-[251px] bg-white border border-black mx-auto">
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-light'>01</p>
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-medium w-[250px] mt-[40px]'>{t('services.41')}</p>
                            <p className='textcolor text-[12px] font-light w-[300px] mx-[20px] mt-[12px]'>{t('services.44')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-[251px] bg-white border border-black mx-auto">
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-light'>02</p>
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-medium w-[250px] mt-[40px]'>{t('services.42')}</p>
                            <p className='textcolor text-[12px] font-light w-[300px] mx-[20px] mt-[12px]'>{t('services.45')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="w-[335px] md:w-full md:mx-[20px] h-[251px] bg-white border border-black mx-auto">
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-light'>03</p>
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-medium w-[250px] mt-[40px]'>{t('services.43')}</p>
                            <p className='textcolor text-[12px] font-light w-[300px] mx-[20px] mt-[12px]'>{t('services.46')}</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className='flex mx-auto w-[335px] mt-[20px]'>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] rotate-180'
                    />
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px] ml-auto'
                    />
                </div>
            </div>
        </div>
    </>
  );
}

export default Advantages;
