import React, { useState } from "react";

import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next";

import logo from "../img/avad.svg"

function MobileMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const location = useLocation();

    const { t } = useTranslation();

    return (
        <div>
            <div className="relative z-[999] w-[30px] h-[25px] cursor-poroboto flex flex-col justify-between xl::hidden" onClick={toggleMenu}>
                <div className={`w-full h-[1px] transition duration-300 transform ${isOpen ? 'rotate-45 translate-y-[9px] bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
                <div className={`w-full h-[1px] bg-[#000] transition duration-300 transform ${isOpen ? 'hidden' : 'block'}`}></div>
                <div className={`w-full h-[1px]  transition duration-300 transform ${isOpen ? '-rotate-45 -translate-y-[15px] bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
            </div>
            <div className={`fixed top-0 right-0 bottom-0 w-full md:w-1/2 bg-white z-50 transform transition-all duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="flex flex-col items-center mt-[50px] gap-[15px]">
                    <img src={logo} alt="" className="w-[100px]"/>
                    <Link to="/services"><p className={`text-[18px] roboto ${location.pathname === '/services' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header.1')}</p></Link>
                    <Link to="/about"><p className={`text-[18px] roboto ${location.pathname === '/about' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header.2')}</p></Link>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;